import React from "react";
import AcademyHeader from "./AcademyHome/AcademyHeader";
import { Outlet } from "react-router-dom";
import AcademyFooter from "./AcademyHome/AcademyFooter";

const AcademyLayout = () => {
  return (
    <>
      <AcademyHeader />
      <main>
        <Outlet />
      </main>
      <AcademyFooter />
    </>
  );
};

export default AcademyLayout;
