import React from "react";
import bookIcon from "../assets/book-open-svgrepo-com.svg";
import { Link } from "react-router-dom";
const HowItWorks = ({ processPaths }) => {
  return (
    <section className="howItWorks">
      <div className="innerHowItWorks">
        <div className="howItWorksTitle">
          <img src={bookIcon} alt="" className="bookIcon" />
          <p className="howItWorksText">How it works</p>
        </div>

        <h1 className="howItWorksHead">
          <span className="mrkBld"> Pathway to success: </span> Learn from
          certified and experienced industry experts
        </h1>

        <div className="processWrap">
          <ol className="process">
            {processPaths.map(({ stepPoint, stepSubText }) => (
              <li className="stepPoint" key={stepPoint}>
                {" "}
                <span className="point"> {stepPoint} </span>
                <span className="subText">{stepSubText}</span>
              </li>
            ))}
          </ol>
        </div>

        <p className="joinCall">
          {" "}
          <span className="mrkSmBld">
            {" "}
            Ready to Transform your career?
          </span>{" "}
          Join Feghas Innovation Hub today.
        </p>

        <Link to="/Register" className="joinNowBtn">
          Join Now
        </Link>
      </div>
    </section>
  );
};

export default HowItWorks;
