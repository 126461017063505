import React, { useEffect } from "react";

const FirstSectionAboutPG = () => {
  useEffect(() => {
    document.title = "About Us | Feghas Solutions";
  }, []);
  return (
    <section className="firstSectionAboutPG">
      <div className="innerFirstSectionAboutUsPG">
        <p className="headTextAbtUs">We are Feghas Solutions</p>
        <div className="aboutUsHeroTextWrap">
          <h1 className="heroPhrase black">We set our business to</h1>
          <h1 className="heroPhrase orange">scale with our solutions </h1>
        </div>
        <div className="pictureDisplay">
          <div className="firstFrame"></div>
          <div className="secondFrame"></div>
        </div>
      </div>
    </section>
  );
};

export default FirstSectionAboutPG;
