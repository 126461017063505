import React from "react";
import arrowDiagIcon from "../assets/arrow-up-right-svgrepo-com.svg";

const OurStory = () => {
  return (
    <section className="ourStory">
      <div className="innerOurStory">
        <div className="ourStoryHeadCall">
          <p className="ourStoryText">Our Story</p>
          <img src={arrowDiagIcon} alt="" className="arrowDiagIcon" />
        </div>

        <div className="ourStoryExplains">
          <h1 className="ourStoryHeadText">
            We are a group of <span className="redClr">listeners</span>,{" "}
            <span className="greenClr">thinkers</span> and{" "}
            <span className="blueClor">doers</span>
          </h1>

          <p className="ourStorySubtextFirst">
            We provide customized business solutions with robust strategy, based
            on a close mutual understanding of our clients’ needs, market
            dynamics, and potentials.
          </p>

          <p className="ourStorySubtextFirst">
            Feghas Solutions Limited is a business solutions company that
            specializes in Information and Communication Technology (ICT),
            Training, and Consultancy. We focus on helping organization boost
            productivity/profitability, and drive the sustainable growth of
            businesses to meet objectives
          </p>
        </div>
      </div>
    </section>
  );
};

export default OurStory;
