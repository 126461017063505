import React, { useEffect } from "react";

const AboutUsHero = () => {
  useEffect(() => {
    document.title = "About Us | Feghas Academy";
  }, []);

  return (
    <section className="aboutHero">
      <div className="innerAboutus">
        <h1 className="aboutUsHeroHead">About Us</h1>
        <p className="aboutUsSubtext">
          Master a subject by deepening your knowledge, gaining experience, and
          refining skills through practice and continuous learning.
        </p>
        <div className="aboutUsHeroBtn">
          <p className="aboutUsHeroBtn orangeBtn">Dedicate</p>
          <p className="aboutUsHeroBtn yelloBtn">Mastery</p>
          <p className="aboutUsHeroBtn greenBtn">Focused-Lens</p>
          <p className="aboutUsHeroBtn blueBtn">Global Result</p>
        </div>
      </div>
    </section>
  );
};

export default AboutUsHero;
