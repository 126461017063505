import React from "react";
import easeofuseIcon from "../assets/easeofuse.jpg";

const SolutionsProducts = ({ solutionFeatures }) => {
  return (
    <section className="solutionsProducts">
      <div className="innerSolutionsProducts">
        <div className="solutionProductHeadTxtWrap">
          <h1 className="solutionProductsHeadTxt">
            Our products & solutions are helping businesses achieve their goals
            and objectives faster.
          </h1>

          <p className="solutionProductSubtext">
            We offer a range of digital solutions that were created thoughtfully
            and have been tested and have proven to help organizations meet
            their goals and objectives faster with no stress.
          </p>
        </div>

        <div className="solutionsProductOverviews">
          {solutionFeatures.map(
            ({ featureTitle, featureSubtitle, featureImage }) => (
              <div className="solutionProductCard">
                <h1 className="solutionProductOverview">{featureTitle}</h1>
                <p className="solutionProductSubText">{featureSubtitle}</p>

                <img
                  src={featureImage}
                  alt=""
                  className="solutionsProdOverviewImg"
                />
              </div>
            )
          )}
        </div>
      </div>
    </section>
  );
};

export default SolutionsProducts;
