import React, { useEffect, useState } from "react";
import "../Main.css";
import contactUsImg from "../assets/contactUsImage.svg";
import { Link } from "react-router-dom";
import FormFeedBackMain from "../FormFeedback/FormFeedBackMain";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    topic: "",
    message: "",
  });

  useEffect(() => {
    document.title = "Contact Us | Feghas Solutions";
  }, []);

  const [topic, setButtonValue] = useState(""); // To capture button value
  const [submitStatus, setSubmitStatus] = useState(null);

  // Handle form input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Include button value in the data to be sent
    const finalData = { ...formData, topic };

    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbyM2mmngD6yuEJTs7uN9AjNrWQV2Dm4-i6qRMLgN2Uixak491j-xsnThHcHfOsTI150/exec",
        {
          method: "POST",
          body: JSON.stringify(finalData),
        }
      );

      const result = await response.json();
      if (result.result === "success") {
        setSubmitStatus({
          type: "success",
          message: "Form submitted successfully!",
          messageSubText:
            "Please do constant check your mail for follow ups and other informations",
        });

        setFormData({
          topic: "",
          message: "",
        });
      } else {
        setSubmitStatus({
          type: "error",
          message: "Failed to submit form!",
          messageSubText: "Please try again",
        });
      }
    } catch (error) {
      //console.error("Error:", error);
      setSubmitStatus({
        type: "error",
        message: "Failed to submit form due to a network error!",
        messageSubText: "Please check your connections",
      });
    }
  };

  // Handle button click and set the button value
  const handleButtonClick = (e) => {
    setButtonValue(e.target.value);
  };
  return (
    <>
      {/* <Header /> */}
      <section className="contactUsPG">
        <div className="innerContactUs">
          <div className="contactUsMain">
            <div className="contactUsFloatingContent">
              <p className="contactUsTitleTxt">contact us</p>
              <div className="contactContentWrap">
                <div className="contactContentWrapOne">
                  <h1 className="contactUsHeadText">
                    How can we help your business?
                  </h1>
                  <p className="contactUsSubtext">
                    If you wish to find out more about how Feghas solutions’
                    products and services can help your business, please contact
                    us, and we will be in touch as soon as possible.
                  </p>
                </div>

                <div className="contactContentWrapTwo">
                  <p className="officeTxt">Offices</p>
                  <p className="addressText">
                    Plot 7,42b Adeyemo Akapo Street, Omole Phase, Ikeja Lagos.
                  </p>
                  <p className="phoneNumber">+234 816 307 1786</p>
                </div>
              </div>

              <h1 className="contactUsInfo">info@feghas.com</h1>

              <div className="contactWrapperBtom">
                <div className="formAndCategoryWrap">
                  <form
                    action=""
                    className="contactUsForm"
                    onSubmit={handleSubmit}
                  >
                    <div className="selectTopicWrap">
                      <p className="selectTopicTitle">Select a topic</p>
                      <div className="topicsWrap">
                        <button
                          type="button"
                          name="topic"
                          className="topic"
                          value="How feghas work"
                          onClick={handleButtonClick}
                        >
                          How feghas work
                        </button>
                        <button
                          type="button"
                          name="topic"
                          className="topic"
                          value="Billing and plans"
                          onClick={handleButtonClick}
                        >
                          Billing and plans
                        </button>
                        <button
                          type="button"
                          name="topic"
                          className="topic"
                          value="Make enquiries"
                          onClick={handleButtonClick}
                        >
                          Make enquiries
                        </button>
                        <button
                          type="button"
                          name="topic"
                          className="topic"
                          value="I have a project i want feghas to work on"
                          onClick={handleButtonClick}
                        >
                          I have a project i want feghas to work on
                        </button>
                        <button
                          type="button"
                          name="topic"
                          className="topic"
                          value="How does feghas pricing works"
                          onClick={handleButtonClick}
                        >
                          How does feghas pricing works
                        </button>
                        <button
                          type="button"
                          name="topic"
                          className="topic"
                          value="How to get started"
                          onClick={handleButtonClick}
                        >
                          How to get started
                        </button>
                      </div>
                    </div>

                    <div className="contactUsComment">
                      <label htmlFor="message" className="contactUsCommentTxt">
                        Or tell us what you need help with?
                      </label>
                      <textarea
                        name="message"
                        id="message"
                        className="contactUsInput"
                        placeholder="Type your message here"
                        required
                        value={formData.message}
                        onChange={handleChange}
                      ></textarea>

                      <button type="submit" className="contactUsSubmit">
                        Send us a message
                      </button>
                    </div>
                  </form>
                </div>

                <img src={contactUsImg} alt="" className="contactUSImg" />
              </div>
            </div>
          </div>

          <div className="whatWeSayContact">
            <h1 className="weAreHereToMeet">
              We are here to meet your needs with our advanced, forward-thinking
              solutions
            </h1>

            <p className="tryFeghas">Try Fegahs Today!</p>

            <Link to="/" type="button" className="whatClientSayContactUs">
              What our client say
            </Link>
          </div>
        </div>
      </section>
      {/* <Footer /> */}

      {submitStatus && (
        <FormFeedBackMain
          type={submitStatus.type}
          message={submitStatus.message}
          messageSubText={submitStatus.messageSubText}
        />
      )}
    </>
  );
};

export default ContactUs;
