import React from "react";
import arrowDiagonal from "../assets/ri_arrow-right-up-line2.svg";

const WeOfferSection = ({ offers }) => {
  return (
    <section className="weOfferSection">
      <div className="innerWeOfferSection">
        <div className="h1AndLearnBtnWrap">
          <h1 className="weOfferText">
            We offer a wide{" "}
            <span className="blueColor"> range of services </span>
            designed to{" "}
            <span className="redColor"> optimize your business </span>
            and <span className="orangeColor">achieve your goals </span>
          </h1>

          <div className="learnMore">
            <button type="button" className="learnMoreBtn">
              Learn more
            </button>
            <img src={arrowDiagonal} alt="" className="arrowDiagonal" />
          </div>
        </div>

        <div className="offerDisplayWrap">
          {offers.map((offer) => (
            <div className="offerDisplay" key={offer.id}>
              <img
                src={offer.offerImage}
                alt=""
                className="offerDisplayImage"
              />
              <p className="offerText">{offer.offerText}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WeOfferSection;
