export const fetchPosts = async (page = 1) => {
  const response = await fetch(
    `https://blog.feghas.com//wp-json/wp/v2/posts?_embed&page=${page}`
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  const data = await response.json();
  const totalPages = response.headers.get("X-WP-TotalPages");
  return { data, totalPages: Number(totalPages) };
};
