import React from "react";
import "../Main.css";
import SolutionsHero from "./SolutionsHero";
import SolutionsProducts from "./SolutionsProducts";
import featureImage from "../assets/representations-user-experience-interface-design.jpg";
import featureImageRep from "../assets/businessman-with-interactive-hologram_1030879-80594.jpg";
import featureImagePerf from "../assets/investment-technology-financial-roi-concepts-increase-arrow-exponential-curve-progress-business-performance-charts-virtual-mobile-phone-action-with-businessman-dark-background_36367-2918.jpg";
import SolutionProducts from "./SolutionProducts";
import ProductsWhatClientSay from "./ProductsWhatClientSay";

const SolutionsPage = () => {
  const solutionFeatures = [
    {
      featureTitle: "Easibility",
      featureSubtitle:
        "Our solutions are easy to use as most processes are fully automated.",
      featureImage: featureImage,
    },
    {
      featureTitle: "Generate reports",
      featureSubtitle:
        "Our solutions are easy to use as most processes are fully automated.",
      featureImage: featureImageRep,
    },
    {
      featureTitle: "Optimize performance",
      featureSubtitle:
        "Our solutions are easy to use as most processes are fully automated.",
      featureImage: featureImagePerf,
    },
  ];

  return (
    <>
      {/* <Header /> */}
      <main>
        <SolutionsHero />
        <SolutionsProducts solutionFeatures={solutionFeatures} />
        <SolutionProducts />
        <ProductsWhatClientSay />
      </main>
      {/* <Footer /> */}
    </>
  );
};

export default SolutionsPage;
