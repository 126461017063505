import React from "react";

const WeHelped = () => {
  return (
    <section className="weHelped">
      <div className="innerWeHelped">
        <h1 className="weHelpedHeadText">
          We’ve Helped Thousands Of Graduates Start Rewarding Careers.
        </h1>

        <div className="weHelpedRates">
          <div className="weHelpedRate">
            <h1 className="weHelpedRateLevel">100k</h1>
            <p className="weHelpedRateLevelSubtext">Learners since 2023</p>
          </div>

          <div className="weHelpedRate">
            <h1 className="weHelpedRateLevel">85%</h1>
            <p className="weHelpedRateLevelSubtext">Employment Rate</p>
          </div>

          <div className="weHelpedRate">
            <h1 className="weHelpedRateLevel">95%</h1>
            <p className="weHelpedRateLevelSubtext">Graduate Satisfaction </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WeHelped;
