import React, { useState } from "react";
import AboutUsHero from "./AboutUsHero";
import UnlockBoundless from "./UnlockBoundless";
import MeetFeghas from "./MeetFeghas";
import MissionStatement from "./MissionStatement";
import Questions from "../AcademyHome/Questions";

const AboutUs = () => {
  const [selected, setSelected] = useState(null);

  const handleExpand = (i) => {
    selected === i ? setSelected(null) : setSelected(i);
  };

  const questionsObj = [
    {
      id: 1,
      question: "What courses does the institute offer",
      answer:
        "Feghas institute provides a diverse range of courses aimed at preparing students for the rapidly evolving technology landscape. Programs commonly offered include Computer Science, which covers foundational topics like programming, algorithms, and system architecture, and Data Science, focusing on big data analytics, machine learning, and statistical methods. Information Technology programs delve into network management, systems administration, and database management, while Software Engineering courses emphasize software development, testing, and project management. Cybersecurity is another critical offering, teaching students how to protect systems and data through encryption, ethical hacking, and secure network design.Feghas institute also offers advanced courses in Artificial Intelligence (AI), where students explore deep learning, neural networks, natural language processing, and AI-driven solutions. These programs are designed to meet the needs of industries seeking skilled professionals in automation, data analysis, and intelligent systems.In addition, the institute may provide specialized courses in emerging technologies such as blockchain, cloud computing, robotics, and Internet of Things (IoT), ensuring students are equipped with the skills needed to thrive in cutting-edge areas of technology.",
    },
    {
      id: 2,
      question: "How is the course structured (online or on-campus)?",
      answer:
        "Courses are available in multiple formats, including on-campus, online, and hybrid options. On-campus courses offer traditional, in-person learning, while online courses provide flexibility with either self-paced modules or live, interactive sessions. Hybrid formats combine both online and in-person elements, allowing students to benefit from virtual lectures and on-campus labs or workshops. The variety of formats is designed to accommodate different learning preferences, schedules, and commitments, making it accessible for both full-time students and working professionals to pursue their education in a way that suits their individual needs.",
    },
    {
      id: 3,
      question: "Are there any hands-on projects or internships?",
      answer:
        "Feghas institutes prioritize practical learning by offering hands-on projects, coding challenges, and internships with industry partners. These opportunities allow students to apply their knowledge in real-world scenarios, gaining valuable experience in problem-solving and innovation. Through these initiatives, students develop practical skills and build industry connections, preparing them for successful careers in the technology sector. This approach ensures they are job-ready and equipped to handle real-world challenges.",
    },
    {
      id: 4,
      question: "How does the institute keep its curriculum updated?",
      answer:
        "Feghas institutes actively collaborate with industry experts and regularly update their curriculum to stay aligned with the latest trends and advancements in technology. This continuous review process ensures that the education provided is relevant, up-to-date, and meets the evolving demands of the tech industry, equipping students with the skills and knowledge needed to excel in their careers.",
    },
    {
      id: 5,
      question: "What equipment do I need to study",
      answer:
        "A reliable computer, a good internet connection, and sometimes specific software depending on your course.",
    },
  ];
  return (
    <>
      {/* <AcademyHeader /> */}
      <main>
        <AboutUsHero />
        <UnlockBoundless />
        <MeetFeghas />
        <MissionStatement />
        <Questions
          selected={selected}
          handleExpand={handleExpand}
          questionsObj={questionsObj}
        />
      </main>
      {/* <AcademyFooter /> */}
    </>
  );
};

export default AboutUs;
