import React, { useEffect } from "react";

const WhyFeghasHero = () => {
  useEffect(() => {
    document.title = "Why Feghas| Feghas Academy";
  }, []);

  return (
    <section className="whyFeghasHero">
      <div className="innerWhyFeghasHero">
        <h1 className="whyFeghasHeadText">Why Feghas</h1>
        <p className="whyFeghasSubtext">
          Master a subject by deepening your knowledge, gaining experience, and
          refining skills through practice and continuous learning.
        </p>
      </div>
    </section>
  );
};

export default WhyFeghasHero;
