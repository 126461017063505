import React, { useEffect } from "react";
import starIcon from "../assets/Star 1.svg";
import ellipseIcon from "../assets/Ellipse 1.svg";
import codeIcon from "../assets/codeIcon.svg";
import circleIcon from "../assets/Group 4.svg";
import arrowIcon from "../assets/ri_arrow-right-up-line.svg";
import { Link } from "react-router-dom";

const Hero = () => {
  useEffect(() => {
    document.title = "Home | Feghas Solutions";
  }, []);

  return (
    <section className="heroSection">
      <div className="innerSection">
        <div className="heroTextAndHeroIcon">
          <div className="herotextWrap">
            <h1 className="heroText ">Specialization in delivering</h1>

            <h1 className="heroText">
              <span className="redClr">world</span>{" "}
              <span className="orangeClr">class</span>{" "}
              <span className="blueClr">solutions</span>{" "}
            </h1>
          </div>
          <div className="iconWrap">
            <div className="innerIconWrap">
              <div className="firstIconWrap">
                <img src={starIcon} alt="" className="iconSize" />
                <img src={ellipseIcon} alt="" className="iconSize" />
                <div className="rectangle"></div>
              </div>

              <div className="secondIconWrap">
                <img src={codeIcon} alt="" className="codeIcon" />
                <img src={circleIcon} alt="" className="iconSizeCircle" />

                <div className="nodges"></div>
              </div>
            </div>
          </div>
        </div>

        <p className="heroSubtext">
          We offer innovative solutions and techniques based on years of
          experience working with various companies. Trust us with your success
        </p>

        <div className="heroBtns">
          <Link to="/solutions" className="heroBtn whiteClr">
            Discover our works
          </Link>
          <img src={arrowIcon} alt="" className="arrowIcon" />
          <Link to="/contactus" className="heroBtn blackClr">
            Speak to someone
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Hero;
