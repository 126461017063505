import React from "react";

const OurTeam = ({ boardOfDirectors, boardOfLeaderShips }) => {
  return (
    <section className="ourTeam">
      <div className="innerOurTeam">
        <h1 className="ourTeamHeadWrap">
          <span className="firstOurTeamhead">Our Team has a over 100</span>
          <span className="secondOurTeamHead">years of experience </span>
        </h1>

        <div className="boardOdDirWrap">
          <h1 className="boardHeadText">Board of Directors</h1>
          <p className="boardOfDirSubText">
            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
            sint.
          </p>

          <div className="directorsWrap">
            {boardOfDirectors.map(
              ({ id, directorImage, directorName, directorTitle }) => (
                <div className="pictureAndStatus" key={id}>
                  <img src={directorImage} alt="" className="directorImg" />
                  <div className="directorNameAndTitle">
                    <p className="directorName">{directorName}</p>
                    <p className="directorTitle">{directorTitle}</p>
                  </div>
                </div>
              )
            )}
          </div>
        </div>

        <div className="boardOdDirWrap">
          <h1 className="boardHeadText">Meet our Leadership</h1>
          <p className="boardOfDirSubText">
            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
            sint.
          </p>

          <div className="leadershipWrap">
            {boardOfLeaderShips.map(
              ({ id, leaderImage, leaderName, leaderTitle }) => (
                <div className="leadersPictureAndStatus" key={id}>
                  <img src={leaderImage} alt="" className="directorImg" />
                  <div className="directorNameAndTitle">
                    <p className="directorName">{leaderName}</p>
                    <p className="directorTitle">{leaderTitle}</p>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurTeam;
