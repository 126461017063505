import React, { useRef, useState } from "react";
import "../AcademyMain.css";
import feghasLogo from "../assets/feghasLogo.png";
import homeIcon from "../assets/homeIcon.svg";
import menuIcon from "../assets/icons8-menu.svg";
import closeIcon from "../assets/icons8-close.svg";
import { Link } from "react-router-dom";

const AcademyHeader = () => {
  const navRef = useRef();
  const btnRef = useRef();
  const [close, setClose] = useState(true);

  const showNavbar = () => {
    navRef.current.classList.toggle("responsiveNav");
    btnRef.current.classList.toggle("responsiveNav");
    setClose((prev) => !prev);
  };

  const closeNavbar = () => {
    setClose((prev) => !prev);
  };

  return (
    <header>
      <img src={feghasLogo} alt="feghasLogo" className="feghasLogo" />

      <nav ref={navRef} className="responsiveNav">
        <ul className="navList">
          <li className="navLink">
            <Link to="/academy" className="homeIconAndText">
              <img src={homeIcon} alt="home" className="home" />
              <span className="homeFeghasUndeline" onClick={showNavbar}>
                {" "}
                Home{" "}
              </span>
            </Link>
          </li>
          <li className="navLink">
            <Link to="/About-Us" onClick={showNavbar}>
              {" "}
              About Us{" "}
            </Link>
          </li>
          <li className="navLink">
            <Link to="/WhyFeghas" onClick={showNavbar}>
              {" "}
              Why Feghas{" "}
            </Link>
          </li>
          <li className="navLink">
            <Link to="/Courses" onClick={showNavbar}>
              {" "}
              Courses{" "}
            </Link>
          </li>
          <li className="navLink">
            <Link to="/Register" onClick={showNavbar}>
              {" "}
              Contact Us{" "}
            </Link>
          </li>
        </ul>
      </nav>

      <Link to="/Register" ref={btnRef} className="contactBtn responsiveNav">
        {" "}
        Register Now
      </Link>

      {close ? (
        <img
          src={menuIcon}
          alt=""
          className="menu sr-only"
          onClick={showNavbar}
        />
      ) : (
        <img
          src={closeIcon}
          alt=""
          className="menu sr-only"
          onClick={showNavbar}
        />
      )}
      {/* <img src={menuIcon} alt="" className='menu sr-only' onClick={showNavbar}/> */}
    </header>
  );
};

export default AcademyHeader;
