import React, { useEffect, useState } from "react";
import { fetchPosts } from "../api";

const Blog = ({ blogImages, autoPlayTime = 3000 }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    document.title = "Blog | Feghas Solutions";
  }, []);

  useEffect(() => {
    const getPosts = async () => {
      setLoading(true);
      try {
        const { data } = await fetchPosts();
        setPosts(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    getPosts();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      nextSlide();
    }, autoPlayTime);

    return () => clearTimeout(timer);
  }, [currentSlide]);

  function nextSlide(slideIndex = currentSlide + 1) {
    const nextSlideIndex = slideIndex >= blogImages.length ? 0 : slideIndex;

    setCurrentSlide(nextSlideIndex);

    //console.log(nextSlideIndex);
  }

  const truncateExcerpt = (excerpt) => {
    const words = excerpt.split(" ");
    if (words.length > 40) {
      return words.slice(0, 40).join(" ") + "...";
    }
    return excerpt;
  };

  // if (loading) return <p>Loading...</p>;

  return (
    <section className="blog">
      <div className="innerBlog">
        <div className="blogImageSlider">
          {blogImages.map(({ imageSlide }, index) => (
            <div
              key={index}
              className="blogImageSlide"
              style={{
                backgroundImage: `url(${imageSlide})`,
                marginLeft: index === 0 ? `-${currentSlide * 100}%` : undefined,
              }}
            ></div>
          ))}
          <div className="blogSlideText">
            <h1 className="blogSlideHeadText">
              Maximizing User Engagement with Effective Push Notifications
            </h1>
            <p className="blogSlideSubText">
              Discover how to boost user engagement with well-crafted push
              notifications. This post explores best practices, design tips, and
              strategies to ensure your notifications capture attention and
              drive interaction.
            </p>
          </div>
        </div>

        <div className="blogIndicator">
          {blogImages.map((_, index) => (
            <div
              key={index}
              className="dots"
              style={
                currentSlide === index
                  ? { opacity: 1, width: "60px", borderRadius: "2px" }
                  : { opacity: 0.5 }
              }
              onClick={() => nextSlide(index)}
            ></div>
          ))}
        </div>

        {!loading && !error && (
          <div className="blogPostsWrap">
            <p className="filterText">Most Recent</p>
            <div className="blogPosts">
              {posts.map((post) => (
                <div className="blogPost" key={post.id}>
                  {post._embedded?.["wp:featuredmedia"]?.[0]?.source_url && (
                    <img
                      src={post._embedded["wp:featuredmedia"][0].source_url}
                      alt={post.title.rendered}
                      className="blogPostImage"
                      /* style={{
                      width: "100%",
                      height: "auto",
                      marginBottom: "10px",
                    }} */
                    />
                  )}
                  {/* <img src={blogImage} alt="" className="blogPostImage" /> */}
                  <div className="blogText">
                    <h1 className="blogHeadText">{post.title.rendered}</h1>
                    <div
                      className="blogSubText"
                      dangerouslySetInnerHTML={{
                        __html: truncateExcerpt(post.excerpt.rendered),
                      }}
                    />
                    {/* <p className="blogSubText">{post.subtext.rendered}</p> */}

                    {/* <div
                    dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}
                  /> */}
                    <a
                      href={post.link}
                      className="blogReadMore"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Read more
                    </a>
                    {/* <div
                    dangerouslySetInnerHTML={{ __html: post.content.rendered }}
                  /> */}
                  </div>
                  {/* <a href="#" className="blogReadMore">
                  Read More ...
                </a> */}
                </div>
              ))}
              {/* <div className="blogPost">
              <img src={blogImage} alt="" className="blogPostImage" />
              <div className="blogText">
                <h1 className="blogHeadText">
                  Maximizing User Engagement with Effective Push Notifications
                </h1>
                <p className="blogSubText">
                  Discover how to boost user engagement with well-crafted push
                  notifications. This post explores best practices, design tips,
                  and strategies to ensure your notifications capture attention
                  and drive interaction.
                </p>
              </div>
              <a href="#" className="blogReadMore">
                Read More ...
              </a>
            </div>

            <div className="blogPost">
              <img src={blogImage} alt="" className="blogPostImage" />
              <div className="blogText">
                <h1 className="blogHeadText">
                  Maximizing User Engagement with Effective Push Notifications
                </h1>
                <p className="blogSubText">
                  Discover how to boost user engagement with well-crafted push
                  notifications. This post explores best practices, design tips,
                  and strategies to ensure your notifications capture attention
                  and drive interaction.
                </p>
              </div>
              <a href="#" className="blogReadMore">
                Read More ...
              </a>
            </div>

            <div className="blogPost">
              <img src={blogImage} alt="" className="blogPostImage" />
              <div className="blogText">
                <h1 className="blogHeadText">
                  Maximizing User Engagement with Effective Push Notifications
                </h1>
                <p className="blogSubText">
                  Discover how to boost user engagement with well-crafted push
                  notifications. This post explores best practices, design tips,
                  and strategies to ensure your notifications capture attention
                  and drive interaction.
                </p>
              </div>
              <a href="#" className="blogReadMore">
                Read More ...
              </a>
            </div>

            <div className="blogPost">
              <img src={blogImage} alt="" className="blogPostImage" />
              <div className="blogText">
                <h1 className="blogHeadText">
                  Maximizing User Engagement with Effective Push Notifications
                </h1>
                <p className="blogSubText">
                  Discover how to boost user engagement with well-crafted push
                  notifications. This post explores best practices, design tips,
                  and strategies to ensure your notifications capture attention
                  and drive interaction.
                </p>
              </div>
              <a href="#" className="blogReadMore">
                Read More ...
              </a>
            </div>

            <div className="blogPost">
              <img src={blogImage} alt="" className="blogPostImage" />
              <div className="blogText">
                <h1 className="blogHeadText">
                  Maximizing User Engagement with Effective Push Notifications
                </h1>
                <p className="blogSubText">
                  Discover how to boost user engagement with well-crafted push
                  notifications. This post explores best practices, design tips,
                  and strategies to ensure your notifications capture attention
                  and drive interaction.
                </p>
              </div>
              <a href="#" className="blogReadMore">
                Read More ...
              </a>
            </div>

            <div className="blogPost">
              <img src={blogImage} alt="" className="blogPostImage" />
              <div className="blogText">
                <h1 className="blogHeadText">
                  Maximizing User Engagement with Effective Push Notifications
                </h1>
                <p className="blogSubText">
                  Discover how to boost user engagement with well-crafted push
                  notifications. This post explores best practices, design tips,
                  and strategies to ensure your notifications capture attention
                  and drive interaction.
                </p>
              </div>
              <a href="#" className="blogReadMore">
                Read More ...
              </a>
            </div> */}
            </div>
          </div>
        )}

        {error && <p>Error: {error.message}</p>}
      </div>
    </section>
  );
};

export default Blog;
