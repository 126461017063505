import React from "react";

const AboutSection = () => {
  return (
    <section className="aboutSection">
      <div className="innerAboutSection">
        <p className="aboutText">About Us</p>
        <h1 className="aboutHead">
          Feghas solutions is a company that specializes in delivering cutting
          edge digital solutions to clients in various sectors of the economy.
        </h1>
        <p className="aboutSubtext">
          We focus on helping organizations boost their productivity,
          profitability, and driving the sustainable growth of these businesses
          to meet there objectives. Understanding that organizations are faced
          with myriad of problems…
        </p>
      </div>
    </section>
  );
};

export default AboutSection;
