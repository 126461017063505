import React from 'react'
import amazonLogo from '../assets/amazon-2-logo-svgrepo-com.svg'
import googleLogo from '../assets/google-logo@logotyp.us.svg'
import flutterWave from '../assets/flutterwave-logo@logotyp.us.svg'
import microsoft from '../assets/microsoft-logo@logotyp.us.svg'
import payStack from '../assets/paystack-2.svg'
import interswitch from '../assets/Interswitch_logo.svg'


const Supports = () => {
  return (
    <div className='support'>
      <div className="wrapper">
        <div className="logo logo1"> <img className='logoSize' src={amazonLogo} alt="" /> </div>
        <div className="logo logo2"> <img className='logoSize' src={googleLogo} alt="" /> </div>
        <div className="logo logo3"> <img className='logoSize' src={flutterWave} alt="" /> </div>
        <div className="logo logo4"> <img className='logoSize' src={microsoft} alt="" /> </div>
        <div className="logo logo5"> <img className='logoSize' src={payStack} alt="" /> </div>
        <div className="logo logo6"> <img className='logoSize' src={interswitch} alt="" /> </div>
      </div>
    </div>
  )
}

export default Supports
