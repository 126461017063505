import React from "react";
import "../Main.css";
import loveIcon from "../assets/love-svgrepo-com.svg";
import imageDp from "../assets/confident-business-woman-portrait-smiling-face.jpg";
import { Link } from "react-router-dom";

const WhatClientSay = ({ comments }) => {
  return (
    <section className="whatOurClientSay">
      <p className="whatOurClientSayHead">What our client say</p>
      <div className="whatClientSayScroller">
        <div className="wallOfLove">
          <img src={loveIcon} alt="" className="loveIcon" />
          <p className="wallOfLoveText">Wall of love</p>
        </div>
        {/* {(id, commentText, statusName, statusTitle, statusPic)} */}
        <div className="innerWhatClientSayScroller">
          {comments.map((comment) => (
            <div
              className={`commentContainer commentClientCount${comment.id}`}
              key={comment.id}
            >
              <p className="commentText">{comment.commentText}</p>

              <div className="dpAndStatus">
                <img src={comment.statusPic} alt="" className="dpStatus" />
                <div className="nameAndTitle">
                  <p className="statusName">{comment.statusName}</p>
                  <p className="statusTitle">{comment.statusTitle}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="whatWeSayContact">
        <h1 className="weAreHereToMeet">
          We are here to meet your needs with our cutting edge solutions
        </h1>

        <p className="tryFeghas">Try Fegahs Today!</p>

        <Link to="/contactus" type="button" className="whatClientSayContactUs">
          Contact Us
        </Link>
      </div>
    </section>
  );
};

export default WhatClientSay;
