import React from "react";
import "../Main.css";
import Hero from "./Hero";
import Supports from "./Supports";
import AboutSection from "./AboutSection";
import WeOfferSection from "./WeOfferSection";
import FeghasSolution from "./FeghasSolution";
// import systemDesign from "../assets/undraw_dev_productivity_re_fylf.svg";
import AppDevelopment from "../assets/undraw_mobile_application_re_13u3.svg";
import WebsiteDevelopment from "../assets/undraw_mobile_development_re_wwsn.svg";
import PortalDevelopment from "../assets/undraw_mobile_devices_k1ok.svg";
import systemDesign from "../assets/systemDesign.jpg";
import webDev from "../assets/webDev.jpg";
import appDev from "../assets/appDev.jpg";
import ITSecurity from "../assets/ITSecurity.jpg";
import imageDp from "../assets/confident-business-woman-portrait-smiling-face.jpg";
import WhatClientSay from "./WhatClientSay";
import Header from "./Header";
import Footer from "./Footer";

const Main = () => {
  const offers = [
    {
      id: 1,
      offerImage: systemDesign,
      offerText: "System Design",
    },
    {
      id: 2,
      offerImage: appDev,
      offerText: "App Development",
    },
    {
      id: 3,
      offerImage: webDev,
      offerText: "Website Development",
    },
    {
      id: 4,
      offerImage: ITSecurity,
      offerText: "IT Security",
    },
  ];

  const comments = [
    {
      id: 1,
      commentText:
        "We've seen tremendous results since implementing Telex. Our event management processes have become more efficient, and the automation features have saved us valuable time. It's a fantastic solution for any business ",
      statusName: "Wendy Rice",
      statusTitle: "FancyandCo",
      statusPic: imageDp,
    },
    {
      id: 2,
      commentText:
        "We've seen tremendous results since implementing Telex. Our event management processes have become more efficient, and the automation features have saved us valuable time. It's a fantastic solution for any business ",
      statusName: "Wendy Rice",
      statusTitle: "FancyandCo",
      statusPic: imageDp,
    },
    {
      id: 3,
      commentText:
        "We've seen tremendous results since implementing Telex. Our event management processes have become more efficient, and the automation features have saved us valuable time. It's a fantastic solution for any business ",
      statusName: "Wendy Rice",
      statusTitle: "FancyandCo",
      statusPic: imageDp,
    },
    {
      id: 4,
      commentText:
        "We've seen tremendous results since implementing Telex. Our event management processes have become more efficient, and the automation features have saved us valuable time. It's a fantastic solution for any business ",
      statusName: "Wendy Rice",
      statusTitle: "FancyandCo",
      statusPic: imageDp,
    },
    {
      id: 5,
      commentText:
        "We've seen tremendous results since implementing Telex. Our event management processes have become more efficient, and the automation features have saved us valuable time. It's a fantastic solution for any business ",
      statusName: "Wendy Rice",
      statusTitle: "FancyandCo",
      statusPic: imageDp,
    },
    {
      id: 6,
      commentText:
        "We've seen tremendous results since implementing Telex. Our event management processes have become more efficient, and the automation features have saved us valuable time. It's a fantastic solution for any business ",
      statusName: "Wendy Rice",
      statusTitle: "FancyandCo",
      statusPic: imageDp,
    },
    {
      id: 7,
      commentText:
        "We've seen tremendous results since implementing Telex. Our event management processes have become more efficient, and the automation features have saved us valuable time. It's a fantastic solution for any business ",
      statusName: "Wendy Rice",
      statusTitle: "FancyandCo",
      statusPic: imageDp,
    },
    {
      id: 8,
      commentText:
        "We've seen tremendous results since implementing Telex. Our event management processes have become more efficient, and the automation features have saved us valuable time. It's a fantastic solution for any business ",
      statusName: "Wendy Rice",
      statusTitle: "FancyandCo",
      statusPic: imageDp,
    },
    {
      id: 9,
      commentText:
        "We've seen tremendous results since implementing Telex. Our event management processes have become more efficient, and the automation features have saved us valuable time. It's a fantastic solution for any business ",
      statusName: "Wendy Rice",
      statusTitle: "FancyandCo",
      statusPic: imageDp,
    },
    {
      id: 10,
      commentText:
        "We've seen tremendous results since implementing Telex. Our event management processes have become more efficient, and the automation features have saved us valuable time. It's a fantastic solution for any business ",
      statusName: "Wendy Rice",
      statusTitle: "FancyandCo",
      statusPic: imageDp,
    },
    {
      id: 11,
      commentText:
        "We've seen tremendous results since implementing Telex. Our event management processes have become more efficient, and the automation features have saved us valuable time. It's a fantastic solution for any business ",
      statusName: "Wendy Rice",
      statusTitle: "FancyandCo",
      statusPic: imageDp,
    },
    {
      id: 12,
      commentText:
        "We've seen tremendous results since implementing Telex. Our event management processes have become more efficient, and the automation features have saved us valuable time. It's a fantastic solution for any business ",
      statusName: "Wendy Rice",
      statusTitle: "FancyandCo",
      statusPic: imageDp,
    },
    {
      id: 13,
      commentText:
        "We've seen tremendous results since implementing Telex. Our event management processes have become more efficient, and the automation features have saved us valuable time. It's a fantastic solution for any business ",
      statusName: "Wendy Rice",
      statusTitle: "FancyandCo",
      statusPic: imageDp,
    },
  ];
  return (
    <>
      {/* <Header /> */}
      <main>
        <Hero />
        <Supports />
        <AboutSection />
        <WeOfferSection offers={offers} />
        <FeghasSolution />
        <WhatClientSay comments={comments} />
      </main>
      {/* <Footer /> */}
    </>
  );
};

export default Main;
