import React, { useRef } from "react";
import failIcon from "../assets/cross-svgrepo-com.svg";
import successIcon from "../assets/success-svgrepo-com.svg";
import { Link } from "react-router-dom";

const FormFeedback = ({ type, message, messageSubText }) => {
  const formFeedBtnClose = useRef();

  const showFeedBack = () => {
    formFeedBtnClose.current.classList.toggle("displayNoneFB");
  };
  return (
    <section ref={formFeedBtnClose} className="formFeedBack">
      <div className="innerFormFeedBack">
        <p className="closeFeedBtn" onClick={showFeedBack}>
          X
        </p>
        <img
          src={type == "success" ? successIcon : failIcon}
          alt=""
          className="successIcon"
          style={
            type == "success"
              ? { backgroundColor: "#2cc36b" }
              : { backgroundColor: "red" }
          }
        />
        <div className="formFeedBackText">
          <h1 className="formFeedBackHead">{message}</h1>
          <p className="formFeedBackSubText">{messageSubText}</p>
        </div>
        <Link to="/academy" className="backToHomeFeedback">
          Back to Home{" "}
        </Link>
      </div>
    </section>
  );
};

export default FormFeedback;
