import React from "react";
import businessIcon from "../assets/business.png";
import starIcon from "../assets/star-svgrepo-com.svg";
import suitCase from "../assets/suitcase1-svgrepo-com.svg";
import internetIcon from "../assets/internet-connection-svgrepo-com.svg";
import lineChartIcon from "../assets/globe-network-icon.svg";
import trouphyIcon from "../assets/trophy_13573212.png";

const FeghasSolution = () => {
  return (
    <section className="whyFeghas">
      <div className="innerWhyFegas">
        <div className="whyFeghasCall">
          <div className="whyFegWrapper">
            <img src={starIcon} alt="" className="whyFeghasIcon" />
            <p className="whyFeghasCallText">Why Feghas</p>
          </div>

          <h1 className="HeadText">Profitable and Effective for all brands</h1>
        </div>

        <div className="digitalSolution">
          <div className="digitalSolutionWrap">
            <div className="digitalSolutionImageAndTextWrap">
              <img src={businessIcon} alt="" className="digitalSolutionImg" />
              <h1 className="digitalSolutionHead">
                Cutting-Edge Digital Solutions
              </h1>
              <p className="digitalSolutionSubText">
                Feghas provides innovative tech solutions tailored to industries
                like education, finance, and retail, ensuring clients stay ahead
                in a competitive market.
              </p>
            </div>

            <div className="digitalInnerFlowThrough">
              <p className="imgText">One Step Ahead of the</p>
            </div>
          </div>

          <div className="digitalSolutionWrap2">
            <div className="digitalInnerFlowThrough2Wrap">
              <div className="digitalInnerFlowThrough2">
                <p className="imgText2">Tech Whisperers</p>
              </div>
            </div>
            <div className="digitalSolutionImageAndTextWrap2">
              <img src={suitCase} alt="" className="digitalSolutionImg2" />
              <h1 className="digitalSolutionHead2">Expert ICT Consulting</h1>
              <p className="digitalSolutionSubText2">
                Their ICT consulting services guide businesses in strategic
                technology decisions, maximizing efficiency and growth.
              </p>
            </div>
          </div>

          <div className="digitalSolutionWrap">
            <div className="digitalSolutionImageAndTextWrap">
              <img src={internetIcon} alt="" className="digitalSolutionImg2" />
              <h1 className="digitalSolutionHead">
                Custom Web & App Development
              </h1>
              <p className="digitalSolutionSubText">
                Feghas specializes in building responsive websites and custom
                apps that enhance user experience and streamline operations.
              </p>
            </div>
            <div className="digitalInnerFlowThrough">
              <p className="imgText">One Pixel at a Time</p>
            </div>
          </div>

          <div className="digitalSolutionWrap2">
            <div className="digitalInnerFlowThrough2Wrap">
              <div className="digitalInnerFlowThrough2">
                <p className="imgText2">Make the web Love You</p>
              </div>
            </div>
            <div className="digitalSolutionImageAndTextWrap2">
              <img src={lineChartIcon} alt="" className="digitalSolutionImg2" />
              <h1 className="digitalSolutionHead2">
                Comprehensive Digital Marketing
              </h1>
              <p className="digitalSolutionSubText2">
                Their digital marketing services help businesses reach a wider
                audience, improve brand visibility, and increase engagement.
              </p>
            </div>
          </div>

          <div className="digitalSolutionWrap">
            <div className="digitalSolutionImageAndTextWrap">
              <img src={trouphyIcon} alt="" className="digitalSolutionImg2" />
              <h1 className="digitalSolutionHead">Proven Industry Expertise</h1>
              <p className="digitalSolutionSubText">
                With strategic partnerships and a successful track record,
                Feghas has gained the trust of major clients across sectors.
              </p>
            </div>
            <div className="digitalInnerFlowThrough">
              <p className="imgText">We Know Our Stuff</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeghasSolution;
