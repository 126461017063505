import React from "react";

const Confidence = () => {
  return (
    <section className="confidence">
      <div className="innerConfidence">
        <p className="conText">100+ Courses</p>
        <p className="conText">Career Support</p>
        <p className="conText">Global Level</p>
        <p className="conText">Hands-on experience</p>
        <p className="conText">Remork work</p>
        <p className="conText">Hands-on experience</p>
      </div>
    </section>
  );
};

export default Confidence;
