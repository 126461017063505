import React from "react";

const Questions = ({ selected, handleExpand, questionsObj }) => {
  return (
    <section className="questions">
      <div className="innerQuestions">
        <h1 className="questionsHead">Perhaps, You have a few questions</h1>

        <div className="questionsS">
          {questionsObj.map((questionsObjs, i) => (
            <div
              className="questionWrap"
              key={i}
              onClick={() => handleExpand(i)}
            >
              <div className="questionOne">
                <p className="question">{questionsObjs.question}</p>
                <p className="questionExpands">{selected === i ? "-" : "+"}</p>
              </div>
              <p className={selected === i ? "answer show" : " answer"}>
                {questionsObjs.answer}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Questions;
