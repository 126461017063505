import React from "react";
import meetFeghasImage from "../assets/meetFeghasImage.svg";

const MeetFeghas = () => {
  return (
    <section className="meetFeghas">
      <div className="innerMeetFeghas">
        <div className="meetFeghasImage">
          <img src={meetFeghasImage} alt="" className="meetFeghasImg" />
        </div>

        <div className="meetFeghasTextArea">
          <span className="meetFeghasCTA">
            <p className="meetFeghasOurValue">our values</p>
            <h1 className="meetFeghasExpt">Meet FEGHAS Experts.</h1>
          </span>

          <div className="meetFeghasCTAFocus">
            <h1 className="digital">digital.</h1>
            <h1 className="intelligence">intelligence.</h1>
            <h1 className="advanced">advanced.</h1>
          </div>

          <p className="meetFeghasSubtext">
            Immerse yourself in learning, consistently gain practical
            experience, and hone your skills through persistent practice and
            ongoing education. This approach ensures comprehensive expertise and
            proficiency over time.
          </p>
        </div>
      </div>
    </section>
  );
};

export default MeetFeghas;
