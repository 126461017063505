import React, { useState } from "react";
import becomeExpertImg from "../assets/becomeExpert.svg";

const BecomeExpert = () => {
  const [news, setNews] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbzO3ulTrS9c-lMKXXiogr7oMMIcy-YuZq7g8gaEDeXPtHVTXOmGkTg0arxvbfhS4K8/exec",
        {
          method: "POST",
          body: JSON.stringify(formData),
        }
      );

      const result = await response.json();
      if (result.result === "success") {
        setNews((prev) => !prev);
        setFormData({
          email: " ",
        });
      }
    } catch (error) {
      setNews(false);
    }
  };
  return (
    <section className="becomeExpert">
      <div className="innerBecomeExpert">
        <div className="becomeExpertTextArea">
          <h1 className="becomeExpertHeadText">Become an expert with us</h1>

          <p className="becomeExpertSubText">
            Master a course by deepening your knowledge, gaining experience, and
            refining skills through practice with hands-on experience and
            continuous learning.
          </p>

          <form action="" className="becomeExpertForm" onSubmit={handleSubmit}>
            <label htmlFor="email" className="becomeExpertLabel">
              Get in touch
            </label>
            <div className="inputAndButtion">
              <input
                type="email"
                id="email"
                name="email"
                className="becomeExpertInput"
                placeholder="Your email address"
                required
                value={formData.email}
                onChange={handleChange}
              />

              <button
                type="submit"
                className="becomeExpertBtn"
                style={news ? { backgroundColor: "orange" } : null}
              >
                {news ? "Done!" : "Submit"}
              </button>
            </div>
          </form>
        </div>

        <img src={becomeExpertImg} alt="" className="becomeExpertImage" />
      </div>
    </section>
  );
};

export default BecomeExpert;
