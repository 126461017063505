import React from "react";
import prodClientSay from "../assets/prodWhatClientSay.png";
import { Link } from "react-router-dom";

const ProductsWhatClientSay = () => {
  return (
    <section className="ProductsWhatClientSay">
      <div className="innerProductsWhatClientSay">
        {/* <div className="prodClientSayImageArea"></div> */}
        <img src={prodClientSay} alt="" className="prodClientSayImageArea" />
        <div className="prodClientHeadTxtAndButton">
          <h1 className="prodClientHead">
            Our products help companies reach their goals 4X faster
          </h1>

          <Link to="/contactus" type="button" className="contactUs">
            Contact Us
          </Link>
        </div>
      </div>
    </section>
  );
};

export default ProductsWhatClientSay;
