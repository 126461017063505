import React from "react";
import feghasExperience from "../assets/feghasExperience.svg";
import dpImg from "../assets/confident-business-woman-portrait-smiling-face.jpg";

const WhyFeghasExperience = () => {
  return (
    <section className="WhyFeghasExperience">
      <div className="innerWhyFeghasExperience">
        <div className="whyFeghasExperienceImageArea">
          <img src={feghasExperience} alt="" className="whyFeghasImage" />
        </div>
        <div className="whyFeghasExperienceTextArea">
          <h1 className="whyExperienceHeadText">
            “With no prior experience, I was still able to find success.”
          </h1>
          <p className="whyFeghasExperienceSubText">
            After completing the DevOps Engineering Programme, Yosef now works
            as a project control officer for New York based tech start up from
            Addis, Ethiopia.
          </p>
          <span className="whyFeghasExperienceDetails">
            <p className="whyFegExpName">Yosef Ayele</p>
            <p className="whyFegExpLocation">Loreon Graduate ‘23</p>
            <p className="whyFegExpStat">Starting his own fintech company</p>
          </span>

          <div className="whyFegExpDpAndStatus">
            <img src={dpImg} alt="" className="whyFegExpDpAndStatusImg" />
            <span className="whyFegExpDpAndStatusWrap">
              <h1 className="whyFegExpDpAndStatusName">Kristin Watson</h1>
              <p className="whyFegExpDpAndStatusDate">
                Jun 27, 2020 · 6 min read
              </p>
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyFeghasExperience;
