import React from "react";
import earthGlobe from "../assets/earth-globe-pointer-svgrepo-com.svg";

const Guaranteed = ({ guarateedObjs }) => {
  return (
    <section className="guaranteed">
      <div className="innerGuaranteed">
        <div className="guaranteedHeads">
          <h1 className="guaranteedHeadText">You are guaranteed</h1>
          <p className="guaranteedSubText">Why you should come join us</p>
        </div>

        <div className="guaranteedPoints">
          {guarateedObjs.map(
            ({
              id,
              guaranteedImg,
              guaranteedPoint,
              guaranteedPointSubtext,
            }) => (
              <div className="guaranteedPoint" key={id}>
                <img
                  src={guaranteedImg}
                  alt=""
                  className="guaranteedPointImg"
                />
                <h1 className="guaranteedPointHead">{guaranteedPoint}</h1>
                <p className="guaranteedPointSubtext">
                  {guaranteedPointSubtext}
                </p>
              </div>
            )
          )}
        </div>
      </div>
    </section>
  );
};

export default Guaranteed;
