import React from "react";
import "../Main.css";

const WhatStudentSay = ({ comments }) => {
  return (
    <section className="whatOurStudentSay">
      <p className="whatOurStudentSayHead">What our students say</p>
      <div className="whatStudentSayScroller">
        <div className="innerWhatStudentSayScroller">
          {comments.map((comment) => (
            <div
              className={`studentCommentContainer commentCount${comment.id}`}
              key={comment.id}
            >
              <p className="studentCommentText">{comment.commentText}</p>

              <div className="dpAndStatus">
                <img src={comment.statusPic} alt="" className="dpStatus" />
                <div className="nameAndTitle">
                  <p className="statusName">{comment.statusName}</p>
                  <p className="statusTitle">{comment.statusTitle}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhatStudentSay;
