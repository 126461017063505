import React from "react";
import missionImageArea from "../assets/missionImageArea.svg";

const MissionStatement = () => {
  return (
    <section className="missionStatement">
      <div className="innermissionStatement">
        <div className="missionStateTextArea">
          <p className="missionCTA">our mission</p>

          <h1 className="missionHeadText">
            Our Mission. To elevate people to the height of mastery in
            Technology.
          </h1>

          <p className="missionSubtext">
            To master a subject, you must immerse yourself in it, continuously
            expanding your knowledge base and gaining hands-on experience.
          </p>

          <span className="missionPointWrap">
            <p className="missionPoints">Feghas Academy Mission</p>
            <p className="missionPointsSubtext">
              We’re here to guide you on your journey to mastering essential
              digital skills. With hands-on training, thoughtfully crafted
              courses, and guidance from industry experts, we make learning
              practical and engaging. Our goal is to empower you with the tools
              you need to grow, succeed, and feel confident in the fast-changing
              digital world.
            </p>
          </span>

          <span className="missionPointWrap">
            <p className="missionPoints">Feghas Academy Vision</p>
            <p className="missionPointsSubtext">
              We’re dedicated to helping people build the tech skills and
              confidence they need to thrive in today’s digital world.
            </p>
          </span>
        </div>

        <div className="missionImageAreaWrap">
          <img src={missionImageArea} alt="" className="missionImageArea" />
        </div>
      </div>
    </section>
  );
};

export default MissionStatement;
