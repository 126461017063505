import React from "react";
import AcademyHeader from "../AcademyHome/AcademyHeader";
import AcademyFooter from "../AcademyHome/AcademyFooter";
import WhyFeghasHero from "./WhyFeghasHero";
import WhyFeghasExperience from "./WhyFeghasExperience";
import WeHelped from "./WeHelped";
import Guaranteed from "./Guaranteed";
import earthIcon from "../assets/earth-globe-pointer-svgrepo-com.svg";
import networkIcon from "../assets/networking-cloud-computing-svgrepo-com.svg";
import portfolio from "../assets/portfolio-svgrepo-com.svg";
import practicalLearning from "../assets/learning-brain-svgrepo-com.svg";
import certificateIcon from "../assets/certificate-diploma-achievement-graduation-certification-svgrepo-com.svg";
import careerGrowth from "../assets/line-chart-badged-svgrepo-com.svg";

const WhyFeghas = () => {
  const guarateedObjs = [
    {
      id: 1,
      guaranteedImg: earthIcon,
      guaranteedPoint: "Real-World Experience",
      guaranteedPointSubtext:
        "Learn from industry experts with real-world experience.",
    },

    {
      id: 2,
      guaranteedImg: networkIcon,
      guaranteedPoint: "Networking",
      guaranteedPointSubtext:
        "Connect with other like-minded individuals and professionals in the tech industry.",
    },

    {
      id: 3,
      guaranteedImg: portfolio,
      guaranteedPoint: "Networking",
      guaranteedPointSubtext:
        "Build a portfolio of work to showcase to potential employers.",
    },

    {
      id: 4,
      guaranteedImg: practicalLearning,
      guaranteedPoint: "Practical learning",
      guaranteedPointSubtext:
        "Gain knowledge through hands-on experience, collaboration, and feedback.",
    },

    {
      id: 5,
      guaranteedImg: certificateIcon,
      guaranteedPoint: "Certification",
      guaranteedPointSubtext:
        "Industry recognized certificate which provides a competitive edge in the job market.",
    },

    {
      id: 6,
      guaranteedImg: careerGrowth,
      guaranteedPoint: "Career Growth",
      guaranteedPointSubtext:
        "Enhance job prospects and career advancement opportunities.",
    },
  ];
  return (
    <>
      <WhyFeghasHero />
      <WhyFeghasExperience />
      <WeHelped />
      <Guaranteed guarateedObjs={guarateedObjs} />
    </>
  );
};

export default WhyFeghas;
