import React, { useEffect } from "react";
import acadHeroImg from "../assets/acadHeroImage.svg";
import { Link } from "react-router-dom";

const AcademyHero = () => {
  useEffect(() => {
    document.title = "Home | Feghas Solutions";
  }, []);
  return (
    <section className="academyHero">
      <div className="innerAcademySection">
        <div className="heroContent">
          <div className="heroContentHead">
            <h1 className="acadHeroHead">building you to</h1>
            <h1 className="acadHeroHead">
              {" "}
              <span className="mastery">Mastery</span> is our
            </h1>
            <h1 className="acadHeroHead bluClr">commitment.</h1>
          </div>

          <p className="acadHeroSubText">
            We are here to meet your needs with our advanced, forward-thinking
            solutions
          </p>

          <div className="acadHeroBtns">
            <Link to="/Register" className="acadHeroBtn greenBgClor">
              Join Now
            </Link>
            <Link to="/About-Us" className="acadHeroBtn noBgClr">
              Learn More
            </Link>
          </div>

          <div className="heroRate">
            <span className="rates">
              <p className="rateNum">100+</p>
              <p className="rateStatus">Active Students</p>
            </span>

            <span className="rates">
              <p className="rateNum">20+</p>
              <p className="rateStatus">Tutors</p>
            </span>

            <span className="rates">
              <p className="rateNum">100%</p>
              <p className="rateStatus">Available Resources</p>
            </span>
          </div>
        </div>
        <img src={acadHeroImg} alt="" className="acadHeroImage" />
      </div>
    </section>
  );
};

export default AcademyHero;
