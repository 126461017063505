import React from "react";
import boundlessImg from "../assets/boundlessImg.svg";
import { Link } from "react-router-dom";

const UnlockBoundless = () => {
  return (
    <section className="unlockBoundless">
      <div className="innerBoundless">
        <div className="boundlessImageArea">
          <img src={boundlessImg} alt="" className="boundlessImg" />
        </div>

        <div className="boundlessTextArea">
          <h1 className="boundlessHeadText">
            Unlock boundless growth with limitless learning and infinite
            opportunities.
          </h1>

          <p className="boundlessSubtext">
            Kickstart, change, or advance your career through the best courses,
            Professional Certificates, and degrees offered by leading tutors and
            industry experts, providing you with the skills and credentials
            needed to succeed in your chosen field.
          </p>

          <Link to="/Register" type="button" className="boundlessBtn">
            Enroll
          </Link>
        </div>
      </div>
    </section>
  );
};

export default UnlockBoundless;
