import React, { useEffect } from "react";

const CoursesHero = ({ coursesObjs }) => {
  useEffect(() => {
    document.title = "Courses| Feghas Academy";
  }, []);
  return (
    <section className="coursesHero">
      <div className="innerCoursesHero">
        <h1 className="coursesHeadText">Our Courses</h1>
        <p className="coursesSubtext">
          Where leading creatives come to educate, inspire, and evolve together
        </p>

        <div className="coursesWrap">
          {coursesObjs.map(({ id, courseBg, courseTitle, courseStatus }) => (
            <div
              className="coursesCardWrap"
              style={{ backgroundImage: `url(${courseBg})` }}
              key={courseTitle}
            >
              <div className="coursesNameAndTimeLocal">
                <span className="coursesTitleAndPrac">
                  <h1 className="coursesTitle">{courseTitle}</h1>
                  <p className="handsOn">Hands-on Experience</p>
                </span>

                <button
                  type="button"
                  className="coursesBtn"
                  style={{
                    backgroundColor:
                      courseStatus === "in-Session" ? "#6fae4d" : "#3772ff",
                  }}
                >
                  {courseStatus}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CoursesHero;
