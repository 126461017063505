import React from "react";
import CoursesHero from "./CoursesHero";
import WebsiteDevelopment from "../assets/steptodown.com299241 (1).jpg";
import Iot from "../assets/iot.png";
import network from "../assets/network.svg";
import dataAnalytics from "../assets/DataAnalytics.svg";
import graphicsDesign from "../assets/graphic-design.svg";
import cyberSecurity from "../assets/cybersecurity.svg";
import uiux from "../assets/uiux.jpg";
import videoEdit from "../assets/videoEdit.jpg";
import animation from "../assets/animation.jpg";
import roboticsAI from "../assets/roboticsAI.jpg";
import digitalMarketting from "../assets/digitalMarketting.jpg";
import DevOps from "../assets/Devops.jpg";

const Courses = () => {
  const coursesObjs = [
    {
      id: 1,
      courseBg: WebsiteDevelopment,
      courseTitle: "Web Development",
      courseStatus: "Available",
    },
    {
      id: 2,
      courseBg: Iot,
      courseTitle: "Internet of Things",
      courseStatus: "in-Session",
    },
    {
      id: 3,
      courseBg: network,
      courseTitle: "Network",
      courseStatus: "Available",
    },
    {
      id: 4,
      courseBg: dataAnalytics,
      courseTitle: "Data Analytics",
      courseStatus: "Available",
    },
    {
      id: 5,
      courseBg: graphicsDesign,
      courseTitle: "Graphics Design",
      courseStatus: "Available",
    },

    {
      id: 6,
      courseBg: cyberSecurity,
      courseTitle: "Cybersecurity",
      courseStatus: "Available",
    },
    {
      id: 7,
      courseBg: uiux,
      courseTitle: "UI/UX",
      courseStatus: "Available",
    },
    {
      id: 8,
      courseBg: videoEdit,
      courseTitle: "Video Editing",
      courseStatus: "Available",
    },
    {
      id: 9,
      courseBg: animation,
      courseTitle: "Animation",
      courseStatus: "Available",
    },
    {
      id: 10,
      courseBg: digitalMarketting,
      courseTitle: "Digital Marketting",
      courseStatus: "Available",
    },
    {
      id: 11,
      courseBg: roboticsAI,
      courseTitle: "Robotics/AI",
      courseStatus: "Available",
    },

    {
      id: 12,
      courseBg: DevOps,
      courseTitle: "DevOps",
      courseStatus: "Available",
    },
  ];
  return (
    <>
      <CoursesHero coursesObjs={coursesObjs} />
    </>
  );
};

export default Courses;
