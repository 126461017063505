import React from "react";
import productSol from "../assets/diverse-group-students-taking-online-tests.jpg";
import SchDash from "../assets/Sch++Dash.jpg";
import ClientDetails from "../assets/ClientDetails.PNG";

const SolutionProducts = () => {
  return (
    <section className="solutionProducts">
      <div className="innerSolutionProducts">
        <h1 className="sectionTitle">Products</h1>
        <div className="eduProductWrap">
          <div className="productContent">
            <div className="productContentText">
              <div className="productTitleHead">
                <p className="ourProduct">our product</p>
                <h1 className="productTitle">EDU++</h1>
              </div>

              <p className="productSubtext">
                Edu++ is a web solution for tertiary institutions. It has an
                admintrative portal for the school management and also for the
                lecturer. Payment solution and result management solution is
                also added.
              </p>

              <div className="productsCategory">
                <p className="productCategoryTitle">Admission management </p>
                <p className="productCategoryTitle">Financial Accounting </p>
                <p className="productCategoryTitle">SMS integration </p>
                <p className="productCategoryTitle">Fee management </p>
                <p className="productCategoryTitle">
                  Administrative management{" "}
                </p>
                <p className="productCategoryTitle">Attendance register </p>
                <p className="productCategoryTitle">Result management </p>
                <p className="productCategoryTitle">Time table generation </p>
                <p className="productCategoryTitle">Report generation </p>
              </div>
            </div>
            <img src={productSol} alt="" className="productContentImage" />
          </div>

          <div className="productContent">
            <img src={SchDash} alt="" className="productContentImage" />

            <div className="productContentText">
              <div className="productTitleHead">
                <p className="ourProduct">our product</p>
                <h1 className="productTitle">SCH++</h1>
              </div>

              <p className="productSubtext">
                The “School++” is a web solution used to manage school
                activities. It has an administrative portal for school
                management and teachers. Additional interface for students and
                parent can also be added.
              </p>

              <div className="productsCategory">
                <p className="productCategoryTitle">
                  Student admsission & management{" "}
                </p>
                <p className="productCategoryTitle">Financial Accounting </p>
                <p className="productCategoryTitle">SMS integration </p>
                <p className="productCategoryTitle">
                  Parents & teacher access{" "}
                </p>
                <p className="productCategoryTitle">Attendance register </p>
                <p className="productCategoryTitle">Result management </p>
                <p className="productCategoryTitle">Time table generation</p>
                <p className="productCategoryTitle">
                  Courses & subject management{" "}
                </p>
              </div>
            </div>
          </div>

          <div className="productContent">
            <div className="productContentText">
              <div className="productTitleHead">
                <p className="ourProduct">our product</p>
                <h1 className="productTitle">ITAL</h1>
              </div>

              <p className="productSubtext">
                ITAL is an easy-to-use solution targeting the administration of
                the unbanked. Finance institution can use this solution to keep
                track of transaction, and biometric to authenticate transaction
                by user.
              </p>

              <div className="productsCategory">
                <p className="productCategoryTitle">Deposit funds </p>
                <p className="productCategoryTitle">Financial Accounting </p>
                <p className="productCategoryTitle">SMS integration </p>
                <p className="productCategoryTitle">Withdraw funds </p>
                <p className="productCategoryTitle">Account management </p>
                <p className="productCategoryTitle">Attendance register </p>
                <p className="productCategoryTitle">Transfer funds</p>
                <p className="productCategoryTitle">Loan management </p>
                <p className="productCategoryTitle">Report generation</p>
              </div>
            </div>

            <img src={ClientDetails} alt="" className="productContentImage" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SolutionProducts;
