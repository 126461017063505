import { Router, Routes, Route } from "react-router-dom";
import Main from "./Home/Main";
import AboutPage from "./AboutUsPG/AboutPage";
import SolutionsPage from "./Solution/SolutionsPage";
import Careers from "./CareersPG/Careers";
import ContactUs from "./ContactUsPG/ContactUs";
import AcademyMain from "./AcademyHome/AcademyMain";
import AboutUs from "./AboutUs/AboutUs";
import WhyFeghas from "./WhyFeghas/WhyFeghas";
import Courses from "./Courses/Courses";
import Register from "./Register/Register";
import AcademyLayout from "./AcademyLayout";
import MainLayout from "./MainLayout";
import image1 from "./assets/bg1.jpg";
import image2 from "./assets/bg2.jpg";
import image3 from "./assets/bg3.jpg";
import image4 from "./assets/blogSlide1.jpg";
import image5 from "./assets/blogSlide2.jpg";
import image6 from "./assets/blogSlide3.jpg";
import image7 from "./assets/blogSlide4.jpg";
import image8 from "./assets/blogSlide5.png";
import image9 from "./assets/blogSlide6.jpg";
import image10 from "./assets/blogSlide7.jpg";
import image11 from "./assets/blogSlide8.jpg";

import Blog from "./Blog/Blog";

function App() {
  const regsiterImageSlider = [
    { imageSlide: image1 },
    { imageSlide: image2 },
    { imageSlide: image3 },
  ];

  const blogImages = [
    { imageSlide: image1 },
    { imageSlide: image2 },
    { imageSlide: image3 },
    { imageSlide: image4 },
    { imageSlide: image5 },
    { imageSlide: image6 },
    { imageSlide: image7 },
    { imageSlide: image8 },
    { imageSlide: image9 },
    { imageSlide: image10 },
    { imageSlide: image11 },
  ];
  return (
    <div className="allWrapper">
      <Routes>
        <Route element={<MainLayout />}>
          <Route path="/" element={<Main />} />
          <Route path="/aboutus" element={<AboutPage />} />
          <Route path="/solutions" element={<SolutionsPage />} />
          <Route path="/blog" element={<Blog blogImages={blogImages} />} />
          <Route path="/contactus" element={<ContactUs />} />
        </Route>
        <Route path="/careers" element={<Careers />} />

        <Route element={<AcademyLayout />}>
          <Route path="academy" element={<AcademyMain />} />
          <Route path="About-Us" element={<AboutUs />} />
          <Route path="WhyFeghas" element={<WhyFeghas />} />
          <Route path="Courses" element={<Courses />} />
        </Route>
        <Route
          path="Register"
          element={<Register regsiterImageSlider={regsiterImageSlider} />}
        />
      </Routes>
    </div>
  );
}

export default App;
