import React, { useEffect } from "react";

const SolutionsHero = () => {
  useEffect(() => {
    document.title = "Solution | Feghas Solutions";
  }, []);
  return (
    <section className="solutionHero">
      <div className="innerSolutionHero">
        <h1 className="solutionHerohead">Solutions</h1>
      </div>
    </section>
  );
};

export default SolutionsHero;
