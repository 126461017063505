import React from "react";
import { Link } from "react-router-dom";

const NumberRate = ({ numberRateMetrics }) => {
  return (
    <section className="numberRate">
      <div className="innerNumberRate">
        <p className="headTextNumberRate">Numbers speak well for us</p>

        <div className="numberRater">
          <div className="innerNumberRater">
            {numberRateMetrics.map(({ rate, rateText }) => (
              <div className="numberHeadTxt">
                <h1 className="numberHead">{rate}</h1>
                <p className="numberRateSubtext">{rateText}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="whatWeSayContact">
          <h1 className="weAreHereToMeet">
            We are here to meet your needs with our cutting edge solutions
          </h1>

          <p className="tryFeghas">Try Fegahs Today!</p>

          <Link to="/contactus" className="whatClientSayContactUs">
            Contact Us
          </Link>
        </div>
      </div>
    </section>
  );
};

export default NumberRate;
