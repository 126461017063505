import React from "react";
import "../Main.css";
import FirstSectionAboutPG from "./FirstSectionAboutPG";
import OurStory from "./OurStory";
import OurTeam from "./OurTeam";
import longeolumideBOD from "../assets/longeolumideBOD.png";
import Fred_Akintunwa_MD_CEOBOD from "../assets/Fred_Akintunwa_MD_CEOBOD.png";
import HannahOyebanjoBOD from "../assets/HannahOyebanjoBOD.png";
import OmololaAkintunwaBOD from "../assets/OmololaAkintunwaBOD.png";
import GeorgeImadeBOD from "../assets/GeorgeImadeBOD.png";
import DapoAwosikaBOD from "../assets/DapoAwosikaBOD.png";
import StephenBelloAdminHRLD from "../assets/StephenBelloAdminHRLD.png";
import OlofinsuyiSeyiOperationsLD from "../assets/OlofinsuyiSeyiOperationsLD.png";
import NumberRate from "./NumberRate";
import Footer from "../Home/Footer";
import Header from "../Home/Header";

const AboutPage = () => {
  const boardOfDirectors = [
    {
      id: 1,
      directorImage: longeolumideBOD,
      directorName: "Prof. Olumide Longe",
      directorTitle: "Chairman",
    },
    {
      id: 2,
      directorImage: Fred_Akintunwa_MD_CEOBOD,
      directorName: "Fred Akintunwa",
      directorTitle: "MD/CEO",
    },
    {
      id: 3,
      directorImage: HannahOyebanjoBOD,
      directorName: "Hannah Oyebanjo",
      directorTitle: "Member",
    },
    {
      id: 4,
      directorImage: OmololaAkintunwaBOD,
      directorName: "Omolola Akintunwa",
      directorTitle: "Member",
    },
    {
      id: 5,
      directorImage: GeorgeImadeBOD,
      directorName: "George Imade",
      directorTitle: "Member",
    },
    {
      id: 6,
      directorImage: DapoAwosikaBOD,
      directorName: "Barr. Dapo Awosika",
      directorTitle: "Member",
    },
  ];

  const boardOfLeaderShips = [
    {
      id: 1,
      leaderImage: Fred_Akintunwa_MD_CEOBOD,
      leaderName: "Fred Akintunwa",
      leaderTitle: "MD/CEO",
    },
    {
      id: 2,
      leaderImage: OmololaAkintunwaBOD,
      leaderName: "Omolola Akintunwa",
      leaderTitle: "Executive Director",
    },
    {
      id: 3,
      leaderImage: StephenBelloAdminHRLD,
      leaderName: "Stephen Bello",
      leaderTitle: "Admin/HR",
    },
    {
      id: 4,
      leaderImage: OlofinsuyiSeyiOperationsLD,
      leaderName: "Seyi Olufinsuyi",
      leaderTitle: "Head of Operation",
    },
    /* {
      id: 5,
      leaderImage: image,
      leaderName: "Opeoluwa",
      leaderTitle: "Accountant",
    }, */
  ];

  const numberRateMetrics = [
    {
      rate: 2005,
      rateText: "Brands Launched by Feghas group",
    },
    {
      rate: "10Billion+",
      rateText: "Client Revenue",
    },
    {
      rate: "100+",
      rateText: "Projects executed​",
    },
    {
      rate: "18+",
      rateText: "Award Nominations",
    },
  ];

  return (
    <>
      {/* <Header /> */}
      <main>
        <FirstSectionAboutPG />
        <OurStory />
        <OurTeam
          boardOfDirectors={boardOfDirectors}
          boardOfLeaderShips={boardOfLeaderShips}
        />
        <NumberRate numberRateMetrics={numberRateMetrics} />
      </main>
      {/* <Footer /> */}
    </>
  );
};

export default AboutPage;
