import React from "react";
import webDevIcon from "../assets/courseWebDevBg.svg";
import { Link } from "react-router-dom";

const PopularCourses = ({ courseObjs }) => {
  return (
    <section className="popularCourses">
      <div className="innerPopularCourses">
        <div className="courseCTA">
          <h1 className="courseCat">course category</h1>
          <h1 className="popularCourseTxt">popular courses</h1>
        </div>

        <div className="popularCourseCardWrap">
          {courseObjs.map(({ courseBg, courseTitle, courseStatus }) => (
            <div
              className="popularCourseCard"
              style={{ backgroundImage: `url(${courseBg})` }}
              key={courseTitle}
            >
              <div className="innerPopularCourse">
                <span className="courseTitleAndName">
                  <p className="courseTitle">{courseTitle}</p>
                  <p className="courseGoal">Hands-on xperience</p>
                </span>

                <button
                  type="button"
                  className="courseCardBtn"
                  style={{
                    backgroundColor:
                      courseStatus === "in-Session" ? "#6fae4d" : "#3772ff",
                  }}
                >
                  {courseStatus}
                </button>
              </div>
            </div>
          ))}
        </div>

        <Link to="/Courses" className="courseSeeMoreBtn">
          See more
        </Link>
      </div>
    </section>
  );
};

export default PopularCourses;
